import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["source", "preview"]

    static values = {
        url: String
    }

    connect() {
        this._updatePreview(this.sourceTarget.value)
        this.sourceTarget.addEventListener('input', this._onChange.bind(this), false)
    }

    _onChange(event) {
        this._updatePreview(event.target.value)
    }

    _updatePreview(message) {
        const link = `<a target="_blank" class="text-cyan-500 hover:text-cyan-700" href="${this.urlValue}">${this.urlValue}</a>`
        this.previewTarget.innerHTML = `${message} ${link}`
    }
}
