import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["sequenceButton", "form", "deleteButton", "selectAll"]

    connect() {
        this.formTarget.addEventListener('change', this._onChange.bind(this), false)
        this.selectAllTarget.addEventListener('change', this._onSelectAll.bind(this), false)
    }

    _onChange(event) {
        let contacts = document.querySelectorAll("input[name='contact_ids[]']:checked")
        let contactIds = [...contacts].map((e) => e.value)
        if (contactIds.length) {
            this.sequenceButtonTarget.classList.remove('hidden')
            this.deleteButtonTarget.classList.remove('hidden')
        } else {
            this.sequenceButtonTarget.classList.add('hidden')
            this.deleteButtonTarget.classList.add('hidden')
        }
    }

    _onSelectAll(event) {
        let checked = event.target.checked
        let contacts = document.querySelectorAll("input[name='contact_ids[]']")
        contacts.forEach(contact => {
            if (contact.checked !== checked) {
                contact.click()
            }
        })
    }
}
